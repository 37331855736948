




































































import Vue from 'vue';
import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
import LookupTagRequestViewModel from '@/src/services/viewModel/request/LearnitTags/LookupTagRequestViewModel';
import LearnitTagResourceViewModel from '@/src/services/viewModel/resource/LearnitTagResourceViewModel';

export default Vue.extend({
  auth: true,
  role: ['admin', 'hostadmin'],
  layout: 'layout-hostadmin',
  data: () => ({
    newTag: new LearnitTagResourceViewModel(),
    createDialogVisible: false,
    requestError: undefined as any,
    filter: {
      searchCriteria: undefined as string | undefined,
    },
    columns: [] as {}[],
    list: {},
    pagination: {
      pageSize: 10,
      currentPage: 1,
    } as ResponsePagingInfo,
    innerPagination: {
      'paging.CurrentPage': undefined as number | undefined,
      'paging.PageSize': undefined as number | undefined,
      'paging.IncludeCount': undefined as boolean | undefined,
      'paging.NoPaging': undefined as boolean | undefined,
    },
  }),
  computed: {
    request() {
      return new LookupTagRequestViewModel().fromModel({ ...this.filter, ...this.innerPagination });
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.pagination.currentPage = 1;
        this.innerPagination['paging.CurrentPage'] = 1;
        this.$debounce(() => {
          this.refreshList();
        }, 300, this)();
      },
    },
    request() {
      this.writeRequestToQuery();
    },
  },
  beforeMount() {
    this.getQueryFromRequest();
    this.pagination.currentPage = this.innerPagination['paging.CurrentPage']! ?? 1;
    this.pagination.pageSize = this.innerPagination['paging.PageSize']!;
  },
  mounted() {
    this.columns = [
      { value: '', field: 'filter' },
      { value: this.$t('page.admin.tags.label.caption'), textAlign: 'left', field: 'caption' },
    ];
  },
  methods: {
    getQueryFromRequest() {
      const queries = new LookupTagRequestViewModel();
      queries.fromQuery(this.$routerUtils.getQueryParams());
      this.filter.searchCriteria = queries.searchCriteria;

      this.innerPagination['paging.CurrentPage'] = queries['paging.CurrentPage'];
      this.innerPagination['paging.PageSize'] = queries['paging.PageSize'];
      this.innerPagination['paging.IncludeCount'] = queries['paging.IncludeCount'];
      this.innerPagination['paging.NoPaging'] = queries['paging.NoPaging'];
    },
    async writeRequestToQuery() {
      this.$debounce(() => {
        this.$routerUtils.updateQueryParams(this.request.toQuery());
      }, 200, this.writeRequestToQuery)();
    },
    refreshList() {
      const tagList = this.$refs.tagList as any;
      tagList?.populateList();
    },
    populateData(params: any) {
      this.innerPagination['paging.CurrentPage'] = params.query['paging.CurrentPage'];
      this.innerPagination['paging.PageSize'] = params.query['paging.PageSize'];
      this.innerPagination['paging.IncludeCount'] = params.query['paging.IncludeCount'];
      this.innerPagination['paging.NoPaging'] = params.query['paging.NoPaging'];
      return this.$service.api.learnitTags.lookupTag(this.request);
    },
    showEditDialog() {
      this.newTag = new LearnitTagResourceViewModel();
      this.createDialogVisible = true;
    },
    async createTag(newTag: LearnitTagResourceViewModel) {
      try {
        await this.$service.api.learnitTags.createTag(newTag);
        this.refreshList();
        this.createDialogVisible = false;
      } catch (error: any) {
        this.requestError = error;
      }
    },
    async editTag(id:number, title:string) {
      const newTagInput = await this.$dialog.prompt(this.$t('page.admin.tags.title.editTags'), this.$t('page.admin.tags.label.editCaption'), title);
      if (newTagInput === null) {
        return;
      }
      try {
        const o = new LearnitTagResourceViewModel();
        o.caption = newTagInput;
        o.id = id;
        await this.$service.api.learnitTags.updateTag(o.id!, o);
        this.refreshList();
      } catch (error: any) {
        this.requestError = error;
      }
    },
    async deleteTag(id: number) {
      const confirmed = await this.$dialog.confirm(this.$t('page.admin.tags.deleteConfirmation.title'), this.$t('page.admin.tags.deleteConfirmation.confirmation'));
      if (!confirmed) {
        return;
      }
      try {
        await this.$service.api.learnitTags.deleteTag(id);
        this.refreshList();
      } catch (error: any) {
        this.$dialog.notify(this.$t('page.admin.tags.label.error'), this.$t('page.admin.tags.label.error.tagInUse'));
      }
    },
  },
});
